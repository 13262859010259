<template>
<div>
 
<el-breadcrumb separator-class="el-icon-arrow-right">
   <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>店铺管理</el-breadcrumb-item>
  <el-breadcrumb-item>提现列表</el-breadcrumb-item>
</el-breadcrumb>
 <el-card  style="  overflow: auto; ">
 <!-- 分类搜索 -->
      <el-row>
        <el-col class="mr15" :span="3.3">
          <el-input size="mini" placeholder="店铺名称" @input="keyword" v-model="queryInfo.businessName">
            <el-button slot="append" icon="el-icon-search"/>
          </el-input>
        </el-col>
        <el-col class="mr15" :span="3.3">
           <el-input size="mini" placeholder="提现号" @input="keyword" v-model="queryInfo.withdrawNo">
            <el-button slot="append" icon="el-icon-search"/>
          </el-input>
        </el-col>
     <el-col :span="3.3" style="display: flex;align-items: center;">
          <el-select size="mini" v-model="queryInfo.withdrawStatus" clearable placeholder="提现状态" style=" width: 150px;"  @change="keyword"  class="mr15">
            <el-option
              v-for="(item, index) in orwithdrawStatus"
              :key="index"
               :label="item.label"
             :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
           <el-col :span="3.3" style="display: flex;align-items: center;">
          <el-select size="mini" v-model="queryInfo.withdrawType" clearable placeholder="提现方式" style=" width: 150px;"  @change="keyword"  class="mr15">
            <el-option
              v-for="(item, index) in orderStatust"
              :key="index"
               :label="item.label"
             :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
 <!--用户列表数据展示区域-->
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
  
        ref="singleTable"
        highlight-current-row
        row-key="id"
        :header-cell-style="{'text-align':'center'}"    
       :cell-style="{'text-align':'center'}"
        stripe >
       
     <el-table-column  prop="businessName" label="店铺名称" width="200" show-overflow-tooltip/>
    
        
        
         <!-- <el-table-column prop="payNo" label="支付号" width="200" show-overflow-tooltip/> -->
          <el-table-column prop="userName" label="用户名" width="200" show-overflow-tooltip/>
           
            <el-table-column prop="withdrawMoney" label="提现金额" width="200" show-overflow-tooltip/>
    <el-table-column prop="outPayNo" label="提现付款流水号" width="200" show-overflow-tooltip/>
        <el-table-column prop="withdrawInfo.bankCardNo" label="银行卡号" width="200" show-overflow-tooltip/>
          <el-table-column prop="withdrawStatus" label="支付类型" width="100" show-overflow-tooltip>
        
         <template  slot-scope="scope">
        <p style="float: left;padding-left:10px ;" v-if="scope.row.withdrawType==0">支付宝</p>
        <p style="float: left;padding-left:10px ;" v-else-if="scope.row.withdrawType==1">微信</p>
      <p style="float: left;padding-left:10px ;" v-else-if="scope.row.withdrawType==2">银行卡</p>
        </template>
        </el-table-column>
        
    <el-table-column prop="updateTime" label="提现时间" width="200" show-overflow-tooltip/>
     <el-table-column prop="payTime" label="提现付款时间" width="200" show-overflow-tooltip/>
          <el-table-column prop="withdrawStatus" label="提现状态" width="100" show-overflow-tooltip>
        
         <template  slot-scope="scope">
        <p style="float: left;padding-left:10px ;" v-if="scope.row.withdrawStatus==0">提现中</p>
        <p style="float: left;padding-left:10px ;" v-else-if="scope.row.withdrawStatus==1">通过</p>
      <p style="float: left;padding-left:10px ;" v-else-if="scope.row.withdrawStatus==2">拒绝</p>
        </template>
        </el-table-column>
        <el-table-column label="操作" min-width="150" fixed="right">
           <template  slot-scope="scope">
          <el-popconfirm  @confirm="user(scope.row.id,0)"  title="确定同意商家提现吗？">
           <el-button :disabled="!(scope.row.withdrawStatus==0)"  slot="reference" size="mini"  type="text">同意</el-button>
           </el-popconfirm>
             <el-popconfirm @confirm="user(scope.row.id,1)" style="padding-left: 20px;" title="确定拒绝商家提现吗？">
           <el-button :disabled="!(scope.row.withdrawStatus==0)" slot="reference"  size="mini"  type="text">拒绝</el-button>
           </el-popconfirm>
           
          <!-- <el-button v-if="scope.row.userState==0" size="mini" type="danger">停用</el-button>
          <el-button v-else size="mini" type="success">启用</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
 </el-card>
 <!-- 弹窗查看 -->
 <el-dialog
  title="提现审核"
  class="abow_dialog"
  :visible.sync="dialogVisible"
  width="30%"
  :append-to-body="true"
 >
  <span slot="footer" class="dialog-footer">
   <el-button type="danger" @click="dialogVisible = false">拒绝提现审核</el-button>
    <el-button type="primary" @click="dialogVisible = false">同意提现审核</el-button>
   
  </span>
</el-dialog>
</div>
</template>
<script>
import time from '../../utils/time'
import {searchUserWithdrawList,handleWithdraw} from "@/api";
export default {
data(){
    return{
        dialogVisible:false,
        tableData:[],
      loading:false,
      // 查看用户详情
      useDate:{

      },
      // 查找商品列表参数对象
      queryInfo: {
        current: 1,
      size: 10,
      order:"desc",
       keyword:"",
       page:1,
 
      }, 
      withdrawId:"",
      total: 0,
      orderStatust: [{
          value: 0,
          label: "支付宝",
        },
        {
          value: 1,
          label: "微信",
        },
         {
          value: 2,
          label: "银行卡",
        }],
     
        orwithdrawStatus:[{
          value: 0,
          label: "提现中",
        },
        {
          value: 1,
          label: "提现成功",
        },
         {
          value: 2,
          label: "提现失败",
        },],
    };
  
  
},
created(){
  
this.getPage()
},
   methods: {
       async user(id,e){
          const formData = new FormData()
       formData.append("pass", e);
        formData.append("withdrawId", id);
  const { data: res } = await handleWithdraw(formData)
  if (res.code !== 200) return this.$message.error("审核失败请稍后重试");
  this.$message.success("审核成功");
   this.getPage()
},
    //  模糊搜索
    keyword(){
this.page=1
this.getPage()
    },

    //  获取用户信息
   async getPage(){
  const {data: res} = await searchUserWithdrawList(this.queryInfo)
  this.tableData=res.body.list
    for (let index = 0; index < this.tableData.length; index++) {
 this.tableData[index].updateTime= time(this.tableData[index].updateTime)

 this.tableData[index].withdrawInfo=JSON.parse(this.tableData[index].withdrawInfo)
}

  for (let index = 0; index < this.tableData.length; index++) {
    if(this.tableData[index].payTime){
this.tableData[index].payTime= time(this.tableData[index].payTime)
    }else{
      this.tableData[index].payTime= ""
    }
//  if()
}
 this.total = res.body.total;
    },
    
    handleCurrentChange(data) {
      this.queryInfo.page = data;
      this.getPage()
    },
    handleSizeChange(newSize) {
      this.queryInfo.size = newSize;
     this.getPage()
    },
       // 退出
    logout() {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    // 返回首页
    backHome(){
      this.$router.push('/index')
      window.sessionStorage.removeItem('activePath')
  
      
    }
    }
}
</script>

<style lang="scss">
.el-information{
    .el-col{
        margin-bottom:15px ;
    }
}
.abow_dialog{
    font-weight: bold;
 

    p{
float: left;
    }
}

.el-icon-s-fold,.el-icon-s-unfold{
    font-size: 35px;
    margin:22px ;
    color: #999;
}
.mr15 {
 
  margin-right: 15px;
}
</style>